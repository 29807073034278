<template>
  <div class="overflow-hidden relative h-screen">
    <Header :noPin="!pinExist"/>
    <div class="text-center h-screen overflow-auto pb-52">
      <div class="my-4 mx-6">
          <router-link :to="{ name: 'MobileMenu' }">
              <div class="flex rounded-lg items-center h-14 p-2 mx-2.5 cursor-pointer border-b border-white border-opacity-10 bg-white bg-opacity-10 filter drop-shadow-menuItem text-left">
                  <BaseIcon name="arrowLeftWhite" outline= false />
                  <div class="flex gap-x-2.5">
                      <p class="font-body text-bodyL text-sepiaBlack dark:text-white">{{ $t('timeline.backMenu') }}</p>
                  </div>
              </div>
          </router-link> 
      </div>
      <ul  class="mx-6 rounded-lg text-left">
          <li v-for="(timeline, index) in timelines" :key="timeline.id" >
              <router-link :to="{ path: '/timeline/' + timeline.id }">
                  <MenuItem :number="timeline.index" :text=" (index == 0 && pinExist) ? $t('timeline.actual') : $t('timeline.finished') + ': ' + timeline.date" class="rounded-t-lg"/>
              </router-link> 
          </li>   
      </ul>
    </div>
    <MobileNavigation :pinExist="pinExist" />
  </div>
</template>

<script>
import MobileNavigation from "@/components/mobile/Navigation.vue";
// import DarkMode from '@/components/DarkMode.vue';
import MenuItem from "@/components/mobile/MenuItem.vue"
import store from '@/store'
import Header from "@/components/mobile/Header.vue";
import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'TimeLines',
  data() {
    return {
      name: window.localStorage.name,
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
      timelines: [],
      pinExist: false,
      baseURL: process.env.VUE_APP_AXIOS_URL,
    }
  },
  components: {
    Header,
    MobileNavigation,
    // DarkMode
    MenuItem,
    BaseIcon,
  },
  mounted() {
    this.$i18n.locale = this.lang;
    this.pinCheck();
    this.getTimelines();
  },
  methods: {
    logOut() {
      const pinCheckResult = store.dispatch('logOut');
      pinCheckResult.then(res => {
        if (res != 'success') {
          this.logOutError = true;
        } else {
          this.$router.push('/login');
        }
      }).catch((res) => {
        this.logOutError = true;
      });
    },
    pinCheck() {
      try{
        const result = this.$store.dispatch('pinCheck');
        result.then(res => {
          if (res && res != '') {
            this.pinExist = true;
          } else {
            this.pinExist = false;
          }
        }).catch(() => {
          this.pinExist = false;
        })
      }catch(e){
        this.pinExist = false;
      }
    },
    getTimelines() {
        const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null
        this.axios.get(this.baseURL + `/wp-json/wp/v2/timeline-list`, {params: {user_id: authUser.data.user.id}}, {})
        .then(response => {
            this.timelines = response.data;
        }).catch( () => {
            // console.log(error);
        });
    },
  },
}
</script>

<style scoped>

ul li a div {
  border-radius: 0;
}

ul li:first-of-type a div {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

ul li:last-of-type a div {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

</style>
